import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Form from './components/Form';
import { FormInputProps } from './components/FormInput';

function Register() {
    const navigate = useNavigate();
    interface IRegister {
        username: string;
        email: string;
        password: string;
        passwordConfirmation: string;
        submitDisabled: boolean
        lastError?: string
    }

    const [state, setState] = useState<IRegister>({
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        submitDisabled: false,
    });

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((previous: IRegister) => ({
            ...previous,
            [event.target.name]: event.target.value
        }));
    };

    function escapeRegExp(s: string) {
        // $& means the whole matched string
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
    };

    const inputs: FormInputProps[] = [
        {
            id: 1,
            name: "username",
            label: "Username",
            type: "text",
            value: state.username,
            onChange: onChange,
            required: true,
            pattern: "^[0-9a-z\\.\\-_]{4,64}$",
            errorMessage: "Username must be 4-64 alphanumeric characters in length and may only contain the \".-_\" special characters."
        },
        {
            id: 2,
            name: "email",
            label: "Email",
            type: "email",
            value: state.email,
            onChange: onChange,
            required: true,
            errorMessage: "Enter a valid email address."
        },
        {
            id: 3,
            name: "password",
            label: "Password",
            type: "password",
            value: state.password,
            onChange: onChange,
            required: true,
            pattern: "^[0-9a-zA-Z`~!@#$%^&*\\(\\)_\\-+\\[\\]\\{\\}\\\\\\|:;\"',<\\.>\\?]{20,64}$",
            errorMessage: "Password must between 20-64 characters in length and may contain alphanumberic and the \"`~!@#$%^&*()_-+=[]{}\\|:;\"',<.>/?\" characters."
        },
        {
            id: 4,
            name: "passwordConfirmation",
            label: "Confirm Password",
            type: "password",
            value: state.passwordConfirmation,
            onChange: onChange,
            required: true,
            pattern: escapeRegExp(state.password),
            errorMessage: "Password does not match."
        },
    ]

    const onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        setState((previous: IRegister) => ({
            ...previous,
            submitDisabled: true,
            lastError: undefined,
        }));

        const rawStartRegisterResponse = await fetch('/api/v1/register', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })

        const startContent = await rawStartRegisterResponse.json();
        console.log(startContent)

        if (rawStartRegisterResponse.status !== 200) {
            setState((previous: IRegister) => ({
                ...previous,
                submitDisabled: false,
                lastError: startContent.message,
            }));
            return
        }

        const rawResponse = await fetch('/api/v1/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'csrfToken': startContent.csrfToken,
                'email': state.email,
                'flowID': startContent.flowID,
                'password': state.password,
                'username': state.username,
            }),
        });

        const content = await rawResponse.json();
        console.log(content);
        setState((previous: IRegister) => ({
            ...previous,
            submitDisabled: false,
        }));

        if (rawResponse.status === 200) {
            navigate('/');
        }
    };

    return (
        <div className="Register">
            <Form
                title="Register"
                inputs={inputs}
                onSubmit={onSubmit}
            />
            <div className="error" hidden={typeof state.lastError === 'undefined'}>
                <p>
                    {state.lastError}
                </p>
            </div>
        </div>
    );
}

export default Register;
