import React, { useState } from 'react';

import './FormInput.css';

export interface FormInputProps {
    id: number;
    name: string;
    label: string;
    type: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    pattern?: string | RegExp;
    errorMessage?: string;
};

function FormInput(props: FormInputProps) {
    const [wasFocused, setWasFocused] = useState(false);

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setWasFocused(true);
    };

    return (
        <div className="FormInput">
            <label>{props.label}:</label>
            <input
                className={wasFocused ? "focused" : ""}
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={props.label}
                required={props.required}
                pattern={props.pattern?.toString()}
                onChange={props.onChange}
                onBlur={onBlur}
            />
            <span >{props.errorMessage}</span>
        </div>
    );
}

export default FormInput;
