import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './Dashboard.css';

function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    interface IDashboard {
        userInfo?: any,

    };
    const [state, setState] = useState<IDashboard>({});

    useEffect(() => {
        const fetchUserInfo = async () => {
            const rawResponse = await fetch('/api/v1/auth/self', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            
            if (rawResponse.status === 401) {
                navigate("/login?return=" + encodeURIComponent(location.pathname));
                return;
            }

            const content = await rawResponse.json();
            setState((previous: IDashboard) => ({
                ...previous,
                userInfo: content,
            }));
        };

        fetchUserInfo();
        return () => {
            setState((previous: IDashboard) => ({
                ...previous,
                userInfo: null,
            }))
        };
    }, [location.pathname, navigate]);

    return (
        <div className="Dashboard">
            <h2>User Information</h2>
            <code>
                {state.userInfo ? JSON.stringify(state.userInfo, null, 2) : "Loading..."}
            </code>
        </div>
    );
}

export default Dashboard;
