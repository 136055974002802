import React from 'react';

import './Form.css'
import FormInput, {FormInputProps} from './FormInput'

export interface FormProps {
    submitDisabled?: boolean;
    title: string;
    inputs: FormInputProps[];
    onSubmit: (event: React.ChangeEvent<HTMLFormElement>) => void;
    error?: string;
}

function Form(props: FormProps) {
    return (
        <div className="Form">
            <h1>{props.title}</h1>
            <form onSubmit={props.onSubmit}>
                {props.inputs.map((input: FormInputProps) => (
                    <FormInput key={input.id} {...input} />
                ))}
                <button type="submit" disabled={props.submitDisabled}>Submit</button>
            </form>
            <div className="error">
                <div className="text">{props.error}</div>
            </div>
        </div>
    );
}

export default Form;
