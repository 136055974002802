import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './App.css';
import Login from './Login';
import NotFound from './NotFound';
import Register from './Register';
import Dashboard from './Dashboard';

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            children: [
                {
                    path: "",
                    element: <Dashboard />,
                },
                {
                    path: "login",
                    element: <Login />,
                },
                {
                    path: "register",
                    element: <Register />,
                },
            ],
        },
        {
            path: "*",
            element: <NotFound />
        },
    ]);
    return (
        <div className="App">
            <header className="App-header">
                <RouterProvider router={router} />
            </header>
        </div>
    );
}

export default App;
