import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './Login.css';
import Form from './components/Form';
import { FormInputProps } from './components/FormInput';

function Login() {
    interface IForm {
        username: string;
        password: string;
        submitDisabled: boolean;
        error?: string;
    }

    const [state, setState] = useState<IForm>({username: "", password: "", submitDisabled: false});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const inputs: FormInputProps[] = [
        {
            id: 1,
            name: "username",
            label: "Username",
            type: "text",
            value: state.username,
            onChange: onChange,
            required: true,
        },
        {
            id: 2,
            name: "password",
            label: "Password",
            type: "password",
            value: state.password,
            onChange: onChange,
            required: true,
        },
    ]

    const onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        setState((previous: IForm) => ({
            ...previous,
            submitDisabled: true,
        }));

        const rawStartLoginResponse = await fetch('/api/v1/login', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })

        const startContent = await rawStartLoginResponse.json();

        if (rawStartLoginResponse.status !== 200) {
            setState((previous: IForm) => ({
                ...previous,
                submitDisabled: false,
                error: startContent,
            }));
            return;
        }

        const rawResponse = await fetch('/api/v1/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'username': state.username,
                'password': state.password,
                'csrfToken': startContent.csrfToken,
                'flowID': startContent.flowID,
            }),
        });

        const content = await rawResponse.json();
        console.log(content);

        if (rawResponse.status === 200) {
            const redirect = searchParams.get('return') || '/';
            if (redirect.startsWith('https://')) {
                window.location.href = redirect;
            } else {
                navigate(redirect);
            }
            return;
        }

        setState((previous: IForm) => ({
            ...previous,
            submitDisabled: false,
            error: content.message ? content.message : "Unknown error",
        }));
    };

    return (
        <div className="Login">
            <Form
                title="Login"
                inputs={inputs}
                onSubmit={onSubmit}
                submitDisabled={state.submitDisabled}
                error={state.error}
            />
            <hr />
            <div className="links">
                <a href="register">Register new account</a>
                <a href="recover">Recover account</a>
            </div>
        </div>
    );
}

export default Login;
